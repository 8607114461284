import React from "react";
import logo from "../assets/logo.png";

interface NavbarProps {
  isOpen: boolean;
  onToggle: () => void;
}

const Navbar: React.FC<NavbarProps> = ({isOpen, onToggle}) => {
  return (
    <nav className="bg-slate-300 py-4 px-6 relative">
      <div className="container mx-auto flex justify-between items-center">
        <img src={logo} width="100" height="100" alt="Logo" />
        <div className="flex items-center">
          <button
            className="text-black focus:outline-none focus:shadow-outline"
            onClick={onToggle}>
            <svg
              className="h-6 w-6 transition-transform duration-300 ease-in-out transform"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)"}}>
              <path d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div
          className={`absolute right-4 top-full w-full lg:w-48 bg-white rounded-md shadow-md transition-opacity duration-300 ${
            isOpen
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}>
          <ul>
            {[
              {text: "Test Case Specs", link: "#"},
              {text: "Test case to User Project Links", link: "#"},
              {text: "Results by User Project Links", link: "#"},
              {text: "Results by Test Case Specs", link: "#"},
            ].map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
