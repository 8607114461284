import React, {useState} from "react";
import {FaFolder, FaFolderOpen, FaFile} from "react-icons/fa";

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

interface TreeProps {
  nodes: TreeNode[];
  onMenuItemClick: (itemName: string) => void;
}

const TreeMenu: React.FC<TreeProps> = ({nodes, onMenuItemClick}) => {
  const [openNodes, setOpenNodes] = useState<Record<string, boolean>>({});

  const handleToggle = (nodeName: string) => {
    setOpenNodes(prevOpenNodes => ({
      ...prevOpenNodes,
      [nodeName]: !prevOpenNodes[nodeName],
    }));
  };

  const renderTree = (nodes: TreeNode[], level: number = 0) => {
    return (
      <ul className={`pl-${level * 4}`}>
        {nodes.map(node => (
          <li key={node.name} className="flex flex-col">
            <div className="flex items-center">
              {node.children ? (
                <>
                  <button
                    onClick={() => handleToggle(node.name)}
                    className="focus:outline-none">
                    {openNodes[node.name] ? <FaFolderOpen /> : <FaFolder />}
                  </button>
                  <span
                    className="ml-2 text-black cursor-pointer"
                    onClick={() => onMenuItemClick(node.name)}>
                    {node.name}
                  </span>
                </>
              ) : (
                <>
                  <FaFile />
                  <span
                    className="ml-2 text-black cursor-pointer"
                    onClick={() => onMenuItemClick(node.name)}>
                    {node.name}
                  </span>
                </>
              )}
            </div>
            {node.children && openNodes[node.name] && (
              <div className="ml-4">{renderTree(node.children, level + 1)}</div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return <div className="bg-transparent">{renderTree(nodes)}</div>;
};

export default TreeMenu;
