import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; // Import BrowserRouter and rename it as Router
import TCTDashboard from "./Component/Dashboard";

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<TCTDashboard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
