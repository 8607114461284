import React from "react";
import {Pie} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
interface PieChartProps {
  dashboard: any;
}

const PieChartComponent: React.FC<PieChartProps> = ({dashboard}) => {
  // Extract hub types and their pass counts
  const hubTypePassCounts: {[key: string]: number} = {};

  dashboard.forEach((item: {key: string | number; pass: number}) => {
    if (hubTypePassCounts[item.key]) {
      hubTypePassCounts[item.key] += item.pass;
    } else {
      hubTypePassCounts[item.key] = item.pass;
    }
  });

  const hubTypes = Object.keys(hubTypePassCounts);
  const passCounts = Object.values(hubTypePassCounts);

  const data = {
    labels: hubTypes,
    datasets: [
      {
        data: passCounts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 71, 0.2)",
          "rgba(75, 0, 130, 0.2)",
          "rgba(154, 205, 50, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(201, 203, 207, 1)",
          "rgba(255, 99, 71, 1)",
          "rgba(75, 0, 130, 1)",
          "rgba(154, 205, 50, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="max-w-xl mx-auto pb-5">
      <div className="w-[30rem] h-[30rem]">
        <Pie data={data} />
      </div>
    </div>
  );
};

export default PieChartComponent;
